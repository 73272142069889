import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#007BFF',
        //primary: '#287BFE',
        //secondary: '#424242',
        secondary: '#00346b',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',

        inverse: '#f5f5f5'
      },
    },
  },
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  }
});
