import Vue from 'vue'
import VueRouter from 'vue-router'

import {
  Clients,
  FeeType,
  Works,
  TypeExpense,
  Expenses,
  ExpenseReport,
  Payments,
  JobReports,
  Rates,
  Balance,
  Tax,
  TypeCompany,
  UnfinishedWork,
  HistoryClient,
  TransactionHistory,
  EditWork,
  WorkClient,
  Users,
  CloseBox
} from '../views/private'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/works'
    },
    {
      path: '/admin',
      component: () => import('../views/private/AppPrivate'),
      children: [
        {
          path: '/works',
          name: 'works',
          component: Works
        },
        {
          path: '/clients',
          name: 'clients',
          component: Clients
        },
        {
          path: '/feetype',
          name: 'feetype',
          component: FeeType
        },
        {
          path: '/typeexpense',
          name: 'typeexpense',
          component: TypeExpense
        },
        {
          path: '/expenses',
          name: 'expenses',
          component: Expenses
        },
        {
          path: '/expense-report',
          name: 'expensereport',
          component: ExpenseReport
        },
        {
          path: '/payments',
          name: 'payments',
          component: Payments
        },
        {
          path: '/job-reports',
          name: 'job-reports',
          component: JobReports
        },
        {
          path: '/rates',
          name: 'rates',
          component: Rates
        },{
          path: '/balance',
          name: 'balance',
          component: Balance
        },{
          path: '/tax',
          name: 'tax',
          component: Tax
        },{
          path: '/typecompany',
          name: 'typecompany',
          component: TypeCompany
        },{
          path: '/unfinished-work',
          name: 'unfinished-work',
          component: UnfinishedWork
        },{
          path: '/payments-history',
          name: 'payments-history',
          component: HistoryClient
        },{
          path: '/cancellation-history',
          name: 'cancellation-history',
          component: TransactionHistory
        },{
          path: '/edit-work',
          name: 'edit-work',
          component: EditWork
        }, {
          path: '/client-work',
          name: 'client-work',
          component: WorkClient
        }, {
          path: '/users',
          name: 'users',
          component: Users
        }, {
          path: '/close-box',
          name: 'close-box',
          component: CloseBox
        }
      ]
    },
    {
      path: '/login',
      component: () => import('../views/login/Login')
    }
  ]
})

export default router
