<template>
    <div class="menu-pc">
        <div class="o-menu">
            <div class="o-menu__header d-flex">
                <div class="pa-1 d-flex align-center justify-center o-menu__logo">
                    <v-img
                        src="/imgs/logo_audycer.svg"
                        width="60px"
                    ></v-img>
                </div>
                <div class="o-menu__title-logo d-flex align-center pr-2 ml-2">
                    <div>
                        <h1>DEKATOO R&T</h1>
                        <h1>ASOCIADOS SRL.</h1>
                    </div>
                </div>
            </div>
            <v-list
                nav
                class="o-menu__list"
            >
                <div
                    v-for="(option, index) in listMenu"
                    :key="index"
                >
                    <v-list-group
                        v-if="option.submenu.length>0"
                        no-action
                        :ripple="false"
                        class="mb-1"
                        active-class="o-menu__option"
                    >
                        <template v-slot:activator>
                            <v-list-item-icon class="mr-2">
                                <v-icon class="o-menu__option-icon" color="#ffffffe8">{{option.icon}}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                                <h1 class="text-menu">{{option.title}}</h1>
                            </v-list-item-title>
                        </template>
                        <v-list-item
                            v-for="(child, index) in option.submenu"
                            :key="index"
                            class="pl-4"
                            :to="child.route"
                            :ripple="false"
                            active-class="o-menu__option"
                        >
                            <v-list-item-title class="d-flex align-center">
                                <v-list-item-icon class="mr-2">
                                    <v-icon small class="o-menu__option-icon" color="#ffffffe8">
                                        mdi-arrow-right-bold
                                    </v-icon>
                                </v-list-item-icon>
                                <h1 class="text-menu">{{child.title}}</h1>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list-group>
                    <v-list-item-group v-else >
                        <v-list-item
                            :to="option.route"
                            class="mb-1"
                            :ripple="false"
                            active-class="o-menu__option"
                        >
                            <v-list-item-icon class="mr-2">
                                <v-icon class="o-menu__option-icon" color="#ffffffce">{{option.icon}}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                                <h1 class="text-menu">{{option.title}}</h1>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list-item-group>
                </div>
                <v-list-item-group>
                    <v-list-item
                        class="mb-1"
                        :ripple="false"
                        @click="ac_closeSesion()"
                    >
                        <v-list-item-icon class="mr-2">
                            <v-icon class="o-menu__option-icon" color="#ffffffce">mdi-close</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                            <h1 class="text-menu">Cerrar Sesión</h1>
                        </v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </div>
        <OLoaderOperationComp
            :dialog="dialogSesion"
            smsLoaderOperation="Cerrando Sesión"
        />
    </div>
</template>
<script>
import { User } from '../../models'
import OLoaderOperationComp from '../../components/organisms/OLoaderOperationComp.vue'
export default {
    components: {
        OLoaderOperationComp
    },
    props: {
        listMenu: Array
    },
    data: () => ({
        group: null,
        dialogSesion: false
    }),
    methods: {
        ac_closeSesion() {
            this.dialogSesion = true
            const apitoken = JSON.parse(localStorage.getItem('agapeconsultores')).token
            var header = {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + apitoken,
            }
            User.logout(header).then(() => {
                localStorage.removeItem('agapeconsultores')
                this.dialogSesion = false
                setTimeout(() => {
                    this.$router.push('/login')
                }, 200)
            }).catch(error => {
                console.log(error)
                localStorage.removeItem('agapeconsultores')
                this.dialogSesion = false
                setTimeout(() => {
                    this.$router.push('/login')
                }, 200)
            })
        }
    }
}
</script>