<template>
    <div>
        <div class="d-flex align-center pa-2">
            <h1 class="text-body-title">CIERRE DE CAJA (Bs.)</h1>
            <v-spacer></v-spacer>
            <OBtnHeaderToolTipComp
                smsTooltip="Volver al cierre de caja."
                icon="mdi-database"
                @click="tab=0"
            />
        </div>
        <MLineHeaderComp />
        <div class="d-flex justify-center pa-2 pb-0" v-if="categorieUser!=1">
            <ODualDatesComp
                class="balance__submenu-second"
                @onChangingDate="ac_searchDates"
            />
        </div>
        <v-divider v-if="categorieUser!=1" class="mt-2"></v-divider>
        <v-tabs-items
            style="background:transparent !important"
            v-model="tab"
            :touchless="true"
        >
            <v-tab-item>
                <OLoaderDataComp
                    v-if="dialogLoaderData"
                    class="mt-2"
                    smsLoaderData="Obteniendo Información"
                />
                <div class="mt-2 animation-opacity" v-else>
                    <div class="d-flex align-center">
                        <div style="width:150px">
                            <h1 class="text-body-title text-right">Total Ingresos:</h1>
                        </div>
                        <div style="width:90px">
                            <h1 class="text-body-subtitle text-right">
                                {{totalPayments.toFixed(2)}}</h1>
                        </div>
                        <OBtnHeaderToolTipComp
                            class="ml-2"
                            icon="mdi-eye"
                            smsTooltip="Ver ingresos"
                            @click="ac_showPayments()"
                        />
                    </div>
                    <div style="width:150px" class="mt-1">
                        <h1 class="text-body-title text-right">Trabajos</h1>
                    </div>
                    <div class="d-flex align-center">
                        <div style="width:150px">
                            <h1 class="text-body-subtitle text-right">Honorarios:</h1>
                        </div>
                        <div style="width:90px">
                            <h1 class="text-body-subtitle text-right">
                                {{(totalHonorary - totalBalance).toFixed(2)}}</h1>
                        </div>
                    </div>
                    <div class="d-flex align-center">
                        <div style="width:150px">
                            <h1 class="text-body-subtitle text-right">Balance:</h1>
                        </div>
                        <div style="width:90px">
                            <h1 class="text-body-subtitle text-right">
                                {{totalBalance.toFixed(2)}}</h1>
                        </div>
                    </div>
                    <div class="d-flex align-center">
                        <div style="width:150px">
                            <h1 class="text-body-subtitle text-right">Impuestos:</h1>
                        </div>
                        <div style="width:90px">
                            <h1 class="text-body-subtitle text-right">
                                {{totalTaxs.toFixed(2)}}</h1>
                        </div>
                    </div>
                    <div style="width:150px" class="mt-1">
                        <h1 class="text-body-title text-right">Cobro Deudas</h1>
                    </div>
                    <div class="d-flex align-center">
                        <div style="width:150px">
                            <h1 class="text-body-subtitle text-right">Honorarios:</h1>
                        </div>
                        <div style="width:90px">
                            <h1 class="text-body-subtitle text-right">
                                {{(totalHonoraryDebt - totalBalanceDebt).toFixed(2)}}</h1>
                        </div>
                    </div>
                    <div class="d-flex align-center">
                        <div style="width:150px">
                            <h1 class="text-body-subtitle text-right">Balance:</h1>
                        </div>
                        <div style="width:90px">
                            <h1 class="text-body-subtitle text-right">
                                {{totalBalanceDebt.toFixed(2)}}</h1>
                        </div>
                    </div>
                    <div class="d-flex align-center">
                        <div style="width:150px">
                            <h1 class="text-body-subtitle text-right">Impuestos:</h1>
                        </div>
                        <div style="width:90px">
                            <h1 class="text-body-subtitle text-right">
                                {{totalTaxsDebt.toFixed(2)}}</h1>
                        </div>
                    </div>
                    <div class="d-flex align-center mt-3">
                        <div style="width:150px">
                            <h1 class="text-body-title text-right">Total Gastos:</h1>
                        </div>
                        <div style="width:90px">
                            <h1 class="text-body-subtitle text-right">
                                {{totalExpenses.toFixed(2)}}</h1>
                        </div>
                        <OBtnHeaderToolTipComp
                            class="ml-2"
                            icon="mdi-eye"
                            smsTooltip="Ver gastos"
                            @click="ac_showExpenses()"
                        />
                    </div>
                    <v-divider class="mt-2"></v-divider>
                    <div class="d-flex mt-2">
                        <div style="width:150px">
                            <h1 :style="ac_getColorDiff" class="text-body-title text-right pr-1">EFECTIVO:</h1>
                        </div>
                        <div style="width:90px">
                            <h1 :style="ac_getColorDiff" class="text-body-subtitle text-right">
                                {{(totalPayments-totalExpenses).toFixed(2)}}
                            </h1>
                        </div>
                    </div>
                    <v-divider class="mt-2"></v-divider>
                    <div style="width:150px" class="mt-3">
                        <h1 class="text-body-title text-right">Resumen Efectivo</h1>
                    </div>
                    <div class="d-flex mt-2">
                        <div style="width:150px">
                            <h1 class="text-body-subtitle text-right pr-1">Total Honorarios:</h1>
                        </div>
                        <div style="width:90px">
                            <h1 class="text-body-subtitle text-right">
                                {{(totalSumHonorary - totalSumBalance).toFixed(2)}}
                            </h1>
                        </div>
                    </div>
                    <div class="d-flex mt-2">
                        <div style="width:150px">
                            <h1 class="text-body-subtitle text-right pr-1">Total Impuestos:</h1>
                        </div>
                        <div style="width:90px">
                            <h1 class="text-body-subtitle text-right">
                                {{totalSumTax.toFixed(2)}}
                            </h1>
                        </div>
                    </div>
                    <div class="d-flex mt-2">
                        <div style="width:150px">
                            <h1 class="text-body-subtitle text-right pr-1">Total Balance General:</h1>
                        </div>
                        <div style="width:90px">
                            <h1 class="text-body-subtitle text-right">
                                {{totalSumBalance.toFixed(2)}}
                            </h1>
                        </div>
                    </div>
                </div>
            </v-tab-item>
            <v-tab-item>
                <div class="pa-2">
                    <div class="d-flex">
                        <h1 class="text-body-title mr-1">CANTIDAD INGRESOS:</h1>
                        <h1 class="text-body-subtitle">
                            {{quantityPayments}}
                        </h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="text-body-title mr-1">TOTAL INGRESOS:</h1>
                        <h1 class="text-body-subtitle">
                            {{totalPayments.toFixed(2)}}
                        </h1>
                    </div>
                </div>
                <v-divider></v-divider>
                <OLoaderDataComp
                    v-if="dialogLoaderCloseBox"
                    class="mt-2"
                    smsLoaderData="Obteniendo Ingresos"
                />
                <MNoDataComp
                    smsNotData="SIN DATOS"
                    class="ma-1"
                    v-if="payments.length==0 && !dialogLoaderCloseBox && !viewTable"
                />
                <div class="d-flex justify-center mt-3" v-if="viewTable">
                    <STablePaymentsCloseBoxComp
                        v-if="!dialogLoaderCloseBox"
                        :payments="payments"
                    />
                </div>
                <div class="d-flex justify-center" v-else>
                    <div
                        v-if="!dialogLoaderCloseBox"
                        class="d-flex justify-center flex-wrap"
                        style="width:100%;"
                    >
                        <SCardPaymentCloseBoxComp
                            v-for="(payment, index) in payments"
                            :key="index"
                            :payment="payment"
                            class="ma-2"
                        />
                    </div>
                </div>
                <SPaginationComp
                    class="mt-2"
                    v-if="!dialogLoaderCloseBox && payments.length > 0"
                    :condicionGo="pagePayment < lastPagePayment"
                    :condicionBack="pagePayment > 1"
                    :titlePagination="'Pag. '+pagePayment+' de '+lastPagePayment"
                    @clickback="ac_changePagePayment(false)"
                    @clickgo="ac_changePagePayment(true)"
                />
            </v-tab-item>
            <v-tab-item>
                <div class="pa-2">
                    <div class="d-flex">
                        <h1 class="text-body-title mr-1">CANTIDAD GASTOS:</h1>
                        <h1 class="text-body-subtitle">
                            {{quantityExpenses}}
                        </h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="text-body-title mr-1">TOTAL GASTOS:</h1>
                        <h1 class="text-body-subtitle">
                            {{totalExpenses.toFixed(2)}}
                        </h1>
                    </div>
                </div>
                <v-divider></v-divider>
                <OLoaderDataComp
                    v-if="dialogLoaderCloseBox"
                    class="mt-2"
                    smsLoaderData="Obteniendo Lista"
                />
                <MNoDataComp
                    smsNotData="SIN DATOS"
                    class="ma-1"
                    v-if="expenses.length==0 && !dialogLoaderCloseBox && !viewTable"
                />
                <div class="d-flex justify-center mt-3" v-if="viewTable">
                    <STableExpenseComp
                        v-if="!dialogLoaderCloseBox"
                        :listExpenses="expenses"
                        :itsJustInformation="true"
                    />
                </div>
                <div class="d-flex justify-center flex-wrap" v-else>
                    <SCardExpenseComp
                        v-for="(expense, index) in expenses"
                        :key="index"
                        :expense="expense"
                        class="ma-2"
                        :itsJustInformation="true"
                    />
                </div>
                <SPaginationComp
                    class="mt-2"
                    v-if="!dialogLoaderCloseBox && expenses.length>0"
                    :condicionGo="pageExpense < lastPageExpense"
                    :condicionBack="pageExpense > 1"
                    :titlePagination="'Pag. '+pageExpense+' de '+lastPageExpense"
                    @clickback="ac_changePageExpense(false)"
                    @clickgo="ac_changePageExpense(true)"
                />
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>
<script>
import {
    MLineHeaderComp
} from "../../components/molecules"
import {
    OBtnHeaderToolTipComp,
    OLoaderDataComp,
    ODualDatesComp
} from "../../components/organisms"
import {
    STablePaymentsCloseBoxComp,
    SCardPaymentCloseBoxComp,
    SPaginationComp,
    STableExpenseComp,
    SCardExpenseComp
} from "../../components/species"
import {
    Work,
    Payment,
    Expense
} from "../../models"
import {
    dataGeneralComponetsMixin,
    rulesMixin,
    responseServer,
    userMixin
} from "../../mixins"
export default {
    mixins: [
        dataGeneralComponetsMixin,
        rulesMixin,
        responseServer,
        userMixin
    ],
    components: {
        OBtnHeaderToolTipComp,
        MLineHeaderComp,
        OLoaderDataComp,
        ODualDatesComp,
        STablePaymentsCloseBoxComp,
        SCardPaymentCloseBoxComp,
        SPaginationComp,
        STableExpenseComp,
        SCardExpenseComp
    },
    data: () => ({
        totalExpenses: 0,
        totalHonorary: 0,
        totalTaxs: 0,
        totalHonoraryDebt: 0,
        totalTaxsDebt: 0,
        totalBalance: 0,
        totalBalanceDebt: 0,
        tab: 0,
        dateOne: '',
        dateTwo: '',
        quantityPayments: 0,
        pagePayment: 0,
        lastPagePayment: 0,
        payments: [],
        dialogLoaderCloseBox: false,
        viewTable: true,
        quantityExpenses: 0,
        pageExpense: 0,
        lastPageExpense: 0,
        expenses: [],
        categorieUser: 1
    }),
    computed: {
        totalPayments: function() {
            return this.totalHonorary + this.totalTaxs + this.totalHonoraryDebt + this.totalTaxsDebt;
        },
        ac_getColorDiff: function() {
            if ((this.totalPayments - this.totalExpenses) < 0) return 'color: red'
            return 'color: green'
        },
        totalSumHonorary: function() {
            return this.totalHonorary + this.totalHonoraryDebt;
        },
        totalSumTax: function() {
            return this.totalTaxs + this.totalTaxsDebt;
        },
        totalSumBalance: function() {
            return this.totalBalance + this.totalBalanceDebt;
        }
    },
    methods: {
        processError(error) {
            this.dialogLoaderOperation = false
            this.smsError = this.m_response_smsError(error)
            this.actionError = this.m_response_enableActionBtn(error)
            this.noAccess = this.m_response_autorization(error)
            this.dialogError = true
        },
        getClosebox(dateOne, dateTwo) {
            this.dialogLoaderData = true
            Work.getCloseBox(dateOne, dateTwo).then(response => {
                this.totalHonorary = response.total_honorary;
                this.totalTaxs = response.total_taxs;
                this.totalHonoraryDebt = response.total_honorary_debt;
                this.totalTaxsDebt = response.total_taxs_debt;
                this.totalBalance = response.total_balance;
                this.totalExpenses = response.total_expenses;
                this.totalBalanceDebt = response.total_balance_bebt;
                this.dialogLoaderData = false
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_searchDates(dates = null) {
            this.dateOne = dates ? dates.date1 : ''
            this.dateTwo = dates ? dates.date2 : ''
            setTimeout(()=> {
                this.getClosebox(this.dateOne, this.dateTwo)
            }, 200)
        },
        getPayments(page, dateOne, dateTwo) {
            this.dialogLoaderCloseBox = true
            Payment.paymentsCloseBox(page, dateOne, dateTwo).then(response => {
                this.quantityPayments = response.total
                this.pagePayment = response.current_page
                this.lastPagePayment = response.last_page
                this.payments = response.data
                this.dialogLoaderCloseBox = false
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_showPayments() {
            this.tab = 1
            this.getPayments(1, this.dateOne, this.dateTwo)
        },
        ac_changePagePayment(state) {
            if (state) { ++this.pagePayment }
            else { --this.pagePayment }
            this.getPayments(this.pagePayment, this.dateOne, this.dateTwo)
        },
        getExpenses(page, dateOne, dateTwo) {
            this.dialogLoaderCloseBox = true
            Expense.getExpenseCloseBox(page, dateOne, dateTwo).then(response => {
                this.quantityExpenses = response.total
                this.pageExpense = response.current_page
                this.lastPageExpense = response.last_page
                this.expenses = response.data
                this.dialogLoaderCloseBox = false
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_showExpenses() {
            this.tab = 2
            this.getExpenses(1, '', '')
        },
        ac_changePageExpense(state) {
            if (state) { ++this.pageExpense }
            else { --this.pageExpense }
            this.getExpenses(this.pageExpense, this.dateOne, this.dateTwo)
        },
    },
    mounted() {
        this.categorieUser = JSON.parse(localStorage.getItem('agapeconsultores')).user.categorie
        if (window.innerWidth <= 600) this.viewTable = false
        if (this.m_user_saveToken()) {
            this.getClosebox('', '')
        } else this.$router.replace('/login')
    }
}
</script>