<template>
    <div>
        <div class="d-none">
            <div ref="sheetpayments">
                <OSheetReceiptPaymentComp :receipt="receipt" />
            </div>
        </div>
        <div class="section-print pb-5">
            <div class="clients__header">
                <div class="clients__header-first">
                    <h1 class="text-body-title">RECIBOS</h1>
                    <v-spacer></v-spacer>
                    <OSearchComp
                        class="history-client__search mr-1"
                        label="CI/NIT"
                        v-model="searchClient"
                        @search="ac_searchClient()"
                    />
                    <ODualDatesComp
                        class="works__submenu-date"
                        @onChangingDate="ac_searchDates"
                    />
                </div>
            </div>
            <MLineHeaderComp id="position-scroll" />
            <div class="pa-2">
                <div class="d-flex">
                    <h1 class="text-body-title">Total cantidad de cancelaciones:</h1>
                    <h1 class="text-body-subtitle ml-1">{{total}}</h1>
                </div>
            </div>
            <v-divider></v-divider>
            <OLoaderDataComp
                v-if="dialogLoaderData"
                class="mt-2"
                smsLoaderData="Obteniendo Historial de recibos"
            />
            <MNoDataComp
                smsNotData="SIN RECIBOS"
                class="ma-1 mt-2"
                v-if="listTransactions.length==0 && !dialogLoaderData"
            />
            <div class="d-flex justify-center flex-wrap mt-3">
                <SCardReceiptComp
                    widthCard="420px"
                    v-for="(receipt, index) in listTransactions"
                    :key="index"
                    :receipt="receipt"
                    class="ma-2"
                    @clickprint="ac_showImpressionSheet(receipt)"
                />
            </div>
            <SPaginationComp
                class="mt-2"
                v-if="!dialogLoaderData && listTransactions.length > 0"
                :condicionGo="page < last_page"
                :condicionBack="page > 1"
                :titlePagination="'Pag. '+page+' de '+last_page"
                @clickback="ac_changePage(false)"
                @clickgo="ac_changePage(true)"
            />
        </div>
        <OSmsErrorComp
            :dialog="dialogError"
            :smsError="smsError"
            :actionError="actionError"
            @click="ac_closeSmsError()"
        />
        <OSmsActionComp
            :frameCorrect="frameCorrect"
            :smsAction="smsAction"
            :colorMessage="colorMessage"
            :timeout="timeSucess"
            @click="m_dataGeneral_frameTimeReset()"
        />
    </div>
</template>
<script>
import {
    MLineHeaderComp,
    MNoDataComp
} from '../../components/molecules'
import {
    ODualDatesComp,
    OSearchComp,
    OLoaderDataComp,
    OSmsErrorComp,
    OSheetReceiptPaymentComp,
    OSmsActionComp
} from '../../components/organisms'
import {
    SPaginationComp,
    SCardReceiptComp
} from '../../components/species'
import {
    dataGeneralComponetsMixin,
    responseServer,
    userMixin
} from '../../mixins'
import { Client } from '../../models'
export default {
    mixins: [
        dataGeneralComponetsMixin,
        responseServer,
        userMixin
    ],
    components: {
        ODualDatesComp,
        OSearchComp,
        MLineHeaderComp,
        SPaginationComp,
        OLoaderDataComp,
        OSmsErrorComp,
        SCardReceiptComp,
        MNoDataComp,
        OSheetReceiptPaymentComp,
        OSmsActionComp
    },
    data: () => ({
        searchClient: '',
        listTransactions: [],
        page: 0,
        last_page: 0,
        total: 0,
        client: {},
        date1: '',
        date2: '',
        payments: [],
        receipt: {}
    }),
    methods: {
        processError(error) {
            this.smsError = this.m_response_smsError(error)
            this.actionError = this.m_response_enableActionBtn(error)
            this.noAccess = this.m_response_autorization(error)
            this.dialogError = true
        },
        ac_closeSmsError() {
            if (this.noAccess) {
                localStorage.removeItem('agapeconsultores')
                this.$router.replace('/login')
            } else this.dialogError = false
        },
        getHistoryClient(page, search, date1, date2, topPos) {
            this.listTransactions = []
            this.dialogLoaderData = true
            Client.getCancellationsHistory(page, search, date1, date2).then(response => {
                this.dialogLoaderData = true
                this.listTransactions = response.data
                this.page = response.page
                this.last_page = response.last_page
                this.total = response.total
                this.dialogLoaderData = false
                if (window.innerWidth <= 600) {
                    setTimeout(() => {
                        window.scroll(0, topPos)
                    }, 150)
                }
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_searchClient() {
            if (this.searchClient === null) this.searchClient = ''
            this.getHistoryClient(1, this.searchClient, this.date1, this.date2)
        },
        ac_changePage(state) {
            var myElement = document.getElementById('position-scroll');
            var topPos = parseInt(myElement.offsetTop) - 45;
            if (state) { ++this.page }
            else { --this.page }
            if (window.innerWidth <= 600) {
                window.scroll(0, 0)
                setTimeout(() => {
                    this.getHistoryClient(this.page, this.searchClient, this.date1, this.date2, topPos)
                }, 100)
            } else this.getHistoryClient(this.page, this.searchClient, this.date1, this.date2)
        },
        ac_searchDates(dates = null) {
            this.date1 = dates ? dates.date1 : ''
            this.date2 = dates ? dates.date2 : ''
            setTimeout(()=> {
                this.getHistoryClient(1, this.searchClient, this.date1, this.date2)
            }, 200)
        },
        print(element) {
            let printSection = document.getElementById('printSection')
            var domClone = element.cloneNode(true)
            var nodoImg = document.createElement("img")
            nodoImg.setAttribute("src", '/imgs/logo_print4.jpg')
            nodoImg.onload = function() {
                printSection.appendChild(domClone)
                window.print()
                printSection.innerHTML = ""
            }
        },
        showEvent(message, colorSms) {
            this.smsAction = message
            this.colorMessage = colorSms
            this.frameCorrect = true
            this.timeSucess = setTimeout(() => {
                this.frameCorrect = false
            }, 3000)
        },
        ac_showImpressionSheet(receipt) {
            if (window.innerWidth <= 600) {
                this.showEvent(['Operación no valida',
                    `Solo puede imprimirse en PC`], 'error')
            } else {
                this.receipt = receipt
                this.$nextTick(() => {
                    setTimeout(() => {
                        this.print(this.$refs.sheetpayments);
                    }, 500)
                })
            }
        }
    },
    mounted() {
        if (this.m_user_saveToken()) {
            this.getHistoryClient(1, '', '', '')
        } else this.$router.replace('/login')
    }
}
</script>